/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { fetchArticleWithSlug, useLocale } from '@modules';
import { ChildrenProps } from '@types';
import { MoreSimilarArticleProps, PostDetails } from '@composite';
import { Layout, Paragraph, SectionWithContent, SubTitle } from '@core';
import { getStrapiRoutes } from 'src/modules/networking/routes';

interface MessageProps extends ChildrenProps{
  message: string
}

const styles = {
  messageSection: {
    variant: 'flex:center',
  },
};

const Message = ({ message, children }: MessageProps) => <SectionWithContent sx={styles.messageSection}>
  <SubTitle>{message}</SubTitle>
  {children}
</SectionWithContent>;

const useSlug = () => {
  const [slug, setSlug] = React.useState('');

  const getSlug = () => {
    setSlug(window.location.search.match(/slug=(?<value>[\w-]+)/)?.groups?.value || '');
  };

  React.useEffect(() => getSlug(), []);

  return slug;
};

export default () => {
  const [pageContext, setPageContext] = React.useState<MoreSimilarArticleProps | null>(null);
  const [error, setError] = React.useState<Error | null>(null);
  const locale = useLocale();
  const slug = useSlug();

  const getArticleData = async () => {
    if (slug) {
      try {
        const articles = await fetchArticleWithSlug(locale, slug);
        setPageContext(articles[locale] || articles.de);
      } catch (e) {
        setError(e);
      }
    }
  };

  React.useEffect(() => {
    (async () => getArticleData())();
  }, [slug, locale]);

  return (
    <Layout>
      {!pageContext && (<Message message={error ? `${error.message} with slug "${slug}" for language "${locale}".` : 'Loading...'}>
        {error && <Paragraph>{`You can try the following to resolve: <li> make sure you have used the correct slug:&nbsp;**${slug}** </li><li> switch the language above to &nbsp;**${locale === 'en' ? 'de' : 'en'}**  </li><li> make sure had you connected English and German article on the &nbsp;<a href="${getStrapiRoutes().cms.dashboard}">CMS Admin</a></li>`}</Paragraph>}
      </Message>)}
      {pageContext && <PostDetails {...pageContext} />}

    </Layout>
  );
};
